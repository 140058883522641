html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  font-family: sans-serif;
  -ms-overflow-style: scrollbar;
}

body {
  height: 100%;
  font-family: $font-main;
  background-color: #fff;
  font-weight: 400;
  color: $color-main;
  font-size: $size-main;
  line-height: 20px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  display: flex;
}

main, header, footer {
  width: 100%;
}

*, :after, :before {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

a, article, aside, b, body, button, dd, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, html, i, iframe, img, input, label, li, nav, object, ol, option, p, param, progress, section, select, small, span, strong, sub, sup, table, tbody, td, textarea, tfoot, th, thead, time, tr, ul, video {
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

b, strong {
  font-weight: bold;
}

a {
  color: $color-link;
  text-decoration: none;
  background-color: transparent;

  &:active, &:hover, &:focus {
    outline: 0;
    color: inherit;
    text-decoration: inherit;
    
  }
}

img {
  vertical-align: middle;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $color-main;
  margin: 0;
  height: 0;
  box-sizing: content-box;
}

button, input, optgroup, select, textarea {
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
  text-transform: none;
  outline: none;
  border: none;
  user-select: none;
}

select {
  text-transform: none;
}

button, html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input {
  &[type="reset"], &[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input:read-only {
  cursor: default;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input {
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  line-height: normal;

  &[type="checkbox"], &[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  &[type="number"] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &[type="search"] {
    box-sizing: content-box;
    -webkit-appearance: textfield;

    &::-webkit-search-cancel-button, &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

textarea {
  overflow: auto;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

@media print {
  * {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;

    &:before, &:after {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }
  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline;
    }

    &[href]:after {
      content: " (" attr(href) ")";
    }

    &[href^="#"]:after, &[href^="javascript:"]:after {
      content: "";
    }
  }
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr {
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    max-width: 100% !important;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4, h5, h6 {
  font-size: 1rem;
}

table {
  background-color: transparent;
}

label {
  display: inline-block;
  max-width: 100%;
}

input {
  &[type="search"] {
    box-sizing: border-box;
  }

  &[type="radio"], &[type="checkbox"] {
    margin-top: 1px \9
  ;
    line-height: normal;
  }

  &[type="file"] {
    display: block;
  }

  &[type="range"] {
    display: block;
    width: 100%;
  }
}

select {
  &[multiple], &[size] {
    height: auto;
  }
}

input {
  &[type="file"]:focus, &[type="radio"]:focus, &[type="checkbox"]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &[type="radio"][disabled], &[type="checkbox"][disabled], &[type="radio"].disabled, &[type="checkbox"].disabled {
    cursor: not-allowed;
  }
}

@-ms-viewport {
  width: device-width;
}

input[type="checkbox"]:checked, 
input[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]:checked + label, 
input[type="checkbox"]:not(:checked) + label {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  line-height: 20px;
  cursor: pointer;
}

input[type="checkbox"]:checked + label:before, 
input[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before, 
input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
}

input[type="checkbox"]:checked + label:after, 
input[type="checkbox"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after, 
input[type="checkbox"]:not(:checked) + label:after {
  left: 3px;
  top: 3px;
  width: 14px;
  height: 9px;
  border-radius: 1px;
  border-left: 3px solid #077AE4;
  border-bottom: 3px solid #077AE4;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

input[type="checkbox"]:checked + label:after {
    opacity: 1;
}