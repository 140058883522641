.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 58px;
  background-color: #fff;
  z-index: 100;

  @include lg-max {
    height: 80px;
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__logo {
    width: 193px;
    height: 58px;

    @include lg-max {
      width: 247px;
      height: 74px;
    }
  }

  &__mail {
    position: relative;
    display: flex;
    align-items: center;
    transition: all .3s;

    &-text {
      margin-left: 12px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #000;
      transition: all .3s;
    }

    & svg path {
      transition: all .3s;
    }

    &:hover {
      & span {
        color: #f98200;
      }

      & svg path {
        fill: #f98200;
      }
    }
  }

  &__phone {
    display: none;

    @include lg-max {
      display: inline;
      font-weight: 600;
      font-size: 22px;
      line-height: 31px;
      color: #2F1061;
      transition: all .3s;
    }

    @include xl-max {
      font-size: 24px;
    }

    @media (min-width: 1440px) {
      font-size: 26px;
    }

    &:hover {
      color: #f98200;
    }
    
    &_m {
      display: inline-block;
      margin-left: auto;
      
      @include lg-max {
        display: none;
      }
    }
  }
}