.footer {
  display: block;
  width: 100%;
  padding: 20px 0 40px 0;
  background: linear-gradient(79.71deg, #250D3C -6.61%, #61269D 96.58%);

  @include lg-max {
    padding: 15px 0;
  }

  &__logo {
    width: 201px;
    height: auto;

    &-link {
      display: inline-block;
      margin-bottom: 15px;

      @include lg-max {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    width: 100%;
    max-width: 232px;
    margin: 0 auto 46px auto;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;

    @include lg-max {
      margin: 0 0 0 auto;
      text-align: right;
    }
  }

  &__phone {
    display: inline-block;
    margin-bottom: 57px;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: #fff;
  }

  &__mail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      margin: 0 0 21px 15px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #fff;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    transition: all .3s;

    &:hover {
      background-color: #fff;
      border-radius: 50%;

      & svg path {
        fill: #000;
      }
    }
  }
}