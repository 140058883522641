.first-block {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin-top: 58px;
  padding: 253px 0 64px 0;
  background: linear-gradient(82.28deg, #15064F 0%, #9D2698 78.02%, #270B55 100.45%);

  @include lg-max {
    height: 680px;
    margin-top: 79px;
    padding: 116px 0 129px 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 12px;
    width: calc(100% - 34px);
    max-width: 438px;
    height: 190px;
    margin: 0 17px;
    background-image: url('/build/images/first-block/main.jpg');
    background-size: 100%;
    background-position: center right;
    background-repeat: no-repeat;
    border-radius: 352px 0 0 352px;

    @media (min-width: 470px) {
      left: calc(50% - 219px);
    }

    @include lg-max {
      top: 0;
      right: 0;
      left: auto;
      bottom: 0;
      width: 100%;
      max-width: 675px;
      height: 100%;
      margin: 0;
      background-size: cover;
    }

    @media (min-width: 1500px) {
      max-width: 1040px;
    }
  }

  &__title {
    margin: 0 auto 20px auto;
    max-width: 438px;
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;

    @include lg-max {
      margin: 0 0 20px 0;
      max-width: 634px;
      font-weight: 900;
      font-size: 42px;
      line-height: 52px;
      text-align: left;
    }
  }

  &__desc {
    max-width: 438px;
    margin: 0 auto 18px auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #fff;

    @include lg-max {
      max-width: 462px;
      margin: 0 0 60px 0;
      font-size: 24px;
      text-align: left;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 310px;
    height: 60px;
    margin: 0 auto;
    background: linear-gradient(79.04deg, #F97700 0%, #FA9600 100%);
    box-shadow: 0px 0px 25px rgba(250, 146, 0, 0.25);
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    transition: all .5s;

    @include lg-max {
      margin: 0;
    }

    &:hover {
      background: linear-gradient(79.04deg, #fff 0%, #fff 100%);
      color: #000;
      box-shadow: 0px 0px 25px rgba(250, 250, 250, 0.25);
    }
  }

  &__social {
    display: none;

    @media (min-width: 1084px) {
      position: absolute;
      top: 0;
      left: -60px;
      display: block;
    }

    @media (min-width: 1144px) {
      left: -90px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    transition: all .3s;

    &:hover {
      background-color: #fff;
      border-radius: 50%;

      & svg path {
        fill: #000;
      }
    }
  }
}