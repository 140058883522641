/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: url('/build/fonts/Gilroy/Gilroy-Regular.eot'); /* IE9 Compat Modes */
  src: local('Gilroy'), local('Gilroy-Regular'),
  url('/build/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Regular.woff') format('woff'), /* Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: url('/build/fonts/Gilroy/Gilroy-Medium.eot'); /* IE9 Compat Modes */
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
  url('/build/fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Medium.woff') format('woff'), /* Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-600 - latin_cyrillic */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: url('/build/fonts/Gilroy/Gilroy-Bold.eot'); /* IE9 Compat Modes */
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
  url('/build/fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Bold.woff') format('woff'), /* Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-900 - latin_cyrillic */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src: url('/build/fonts/Gilroy/Gilroy-Extrabold.eot'); /* IE9 Compat Modes */
  src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
  url('/build/fonts/Gilroy/Gilroy-Extrabold.woff2') format('woff2'), /* Super Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Extrabold.woff') format('woff'), /* Modern Browsers */
  url('/build/fonts/Gilroy/Gilroy-Extrabold.ttf') format('truetype'); /* Safari, Android, iOS */
}
