.fos {
  display: block;
  width: 100%;
  height: auto;

  @include lg-max {
    padding-top: 162px;
  }

  &__container-img {
    position: relative;
    width: 100%;
    height: auto;

    @include lg-max {
      height: 663px;
      background-image: url('/build/images/fos/fos-bg.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &_m {
      display: block;
      width: 100%;
      height: 397px;
      background-image: url('/build/images/fos/fos-bg.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__window {
    display: flex;
    justify-content: center;
    width: 100%;

    @include lg-max {
      position: absolute;
      top: -162px;
    }

    &-container {
      padding: 0 15px 54px 15px!important;

      @include lg-max {
        padding: 47px 110px 63px 110px!important;
        background-color: #fff;
        box-shadow: 0 0 50px rgba(23, 7, 39, 0.3);
        border-radius: 4px;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 110%;
    color: #4D4D4D;
    margin-bottom: 28px;

    @include lg-max {
      margin-bottom: 0;
    }
  }

  &__desc {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
  }

  &__input {
    display: block;
    width: 100%;
    max-width: 285px;
    height: 48px;
    padding: 16px 16px 16px 12px;
    margin: 0 auto 23px auto;
    border: 1px solid #4D4D4D;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(77, 77, 77, 0.8);

    @include md-max {
      margin: 0 0 23px 0;
    }

    @include lg-max {
      margin-bottom: 0;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;

    @include md-max {
      justify-content: flex-start;
    }
  }

  &__label-checkbox {
    padding-left: 35px!important;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #4D4D4D;

    &:before {
      top: calc(50% - 10px)!important;
      width: 23px!important;
      height: 23px!important;
      border: none!important;
      background-color: #F1F3F8!important;
    }

      & a {
      text-decoration: underline;
    }
  }

  & input[type="checkbox"]:checked + label:after, input[type="checkbox"]:not(:checked) + label:after {
    top: calc(50% - 5px)!important;
    left: 5px!important;
    border-left: 3px solid #F97800;
    border-bottom: 3px solid #F97800;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 54px;
    margin: 0 auto;
    background: linear-gradient(79.04deg, #F97700 0%, #FA9600 100%);
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    transition: all .3s;

    @include md-max {
      margin: 0;
    }

    &:hover {
      box-shadow: 0 0 25px rgba(250, 146, 0, 0.25);
      color: #fff;
    }

    &:disabled,
    &[disabled]{
      background: linear-gradient(79.04deg, #f9770080 0%, #fa960080 100%);
      cursor: no-drop;
    }
  }

  &__label-hidden {
    position: absolute;
    opacity: 0;
  }
  
  &__loading {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    background-image: url('/build/images/loading.gif');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &-container {
      position: absolute;
      top: 0;
      display: none;
      min-height: 419px;
      margin: 0 auto;
    }
  }

  &__success {
    display: none;
    color: green;
    font-size: 24px;
    text-align: center;
    margin-top: 100px;
  }

  &__error {
    display: none;
    color: red;
    font-size: 24px;
    text-align: center;
    margin-top: 100px;
  }
}