.advantages {
  display: block;
  width: 100%;
  padding: 50px 0;
  background-color: #fff;
  
  @include lg-max {
    padding: 110px 0;
  }

  &__title {
    display: inline-block;
    width: 100%;
    max-width: 425px;
    margin: 0 0 54px 0;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #F95A00;

    @include lg-max {
      max-width: none;
      margin: 0 0 100px 0;
      font-size: 38px;
      line-height: 46px;
    }
  }

  &__img {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    max-width: 395px;
    height: auto;
    margin: 0 auto;

    @include lg-max {
      position: absolute;
      max-width: 445px;
      margin: 0;
    }

    &-block {
      position: relative;
      display: block;
      width: 100%;

      @include lg-max {
        height: 350px;
      }
    }

    &-left {
      left: 0;

      @include lg-max {
        left: -30px;
      }
    }

    &-right {
      right: 0;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 25px;

    &-block {
      &-left {
        @include lg-max {
          padding-right: 100px!important;
        }
      }

      &-right {
        @include lg-max {
          padding-left: 100px!important;
        }
      }
    }

    &:nth-child(odd) {
      & .advantages__item-block-left {
        order: 1;

        @include lg-max {
          order: 0;
        }
      }

      & .advantages__item-block-right {
        max-width: 395px;
        margin: 0 auto;
        order: 0;

        @include lg-max {
          max-width: none;
          margin: 0;
          order: 1;
        }
      }
    }

    &-title {
      max-width: 260px;
      margin: 0 0 35px 0;
      font-weight: bold;
      font-size: 34px;
      line-height: 115%;
      color: #2F1061;

      @include lg-max {
        max-width: 360px;
        margin: 45px 0 35px 0;
      }
    }

    &-desc {
      max-width: 445px;
      margin: 0 0 20px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #4D4D4D;
    }
  }

  &__line {
    @include lg-max {
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: calc(100% + 25px);
      background-color: rgba(0, 0, 0, 0.5);

      &:before {
        content: '';
        position: absolute;
        top: 55px;
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
        background: #3C1F6B;
        border-radius: 50%;
      }

      &-first {
        position: absolute;
        top: 75px;
        left: 50%;
        width: 1px;
        height: calc(100% + 5px);
        background-color: rgba(0, 0, 0, 0.5);

        &:before {
          content: '';
          position: absolute;
          top: -20px;
          left: calc(50% - 15px);
          width: 30px;
          height: 30px;
          background: #3C1F6B;
          border-radius: 50%;
        }
      }

      &-last {
        position: absolute;
        top: 0px;
        left: 50%;
        width: 1px;
        height: 70px;
        background-color: rgba(0, 0, 0, 0.5);

        &:before {
          content: '';
          position: absolute;
          top: 55px;
          left: calc(50% - 15px);
          width: 30px;
          height: 30px;
          background: #3C1F6B;
          border-radius: 50%;
        }
      }
    }
  }
}